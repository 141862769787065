<template>
  <v-row class="justify-center align-center">
    <v-col cols="12" md="5">
      <v-img src="/artwork/verification.png" />
    </v-col>
    <v-col cols="12" md="5">
      <form @submit.prevent="doVerification">
        <div class="headline text-center mb-4">
          {{ $lang("AUTH:VERIFY_EMAIL_TITLE") }}
        </div>
        <v-divider v-if="authUser.temp_email"></v-divider>
        <v-card-actions v-if="authUser.temp_email">
          {{ $lang("New") + " " + $lang("Email") }}
          <v-spacer></v-spacer>
          {{ authUser.temp_email }}
        </v-card-actions>

        <v-divider></v-divider>
        <v-card-text class="text-center">
          {{ $lang("AUTH:VERIFY_EMAIL_HINT") }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <!-- <v-text-field
            prepend-icon="mdi-lock-open"
            :label="$lang('AUTH:VERIFY_EMAIL:INPUT_TEXT_SALT-LABEL')"
            :hint="$lang('AUTH:VERIFY_EMAIL:INPUT_TEXT_SALT-HINT')"
            v-model="salt"
            :error-messages="errors && errors.salt ? errors.salt : []"
          ></v-text-field> -->
          <v-otp-input
            v-model="salt"
            :disabled="wait"
            @finish="onFinish"
            :error-messages="errors && errors.token ? errors.token : []"
          ></v-otp-input>
        </v-card-text>
        <v-card-actions class="mb-6">
          <v-btn block class="primary dark" type="submit">{{
            $lang("AUTH:VERIFY_EMAIL:BUTTON_SUBMIT-TEXT")
          }}</v-btn>
        </v-card-actions>

        <div class="text-center">
          <v-btn text @click="resendCode">{{
            $lang("AUTH:VERIFY_EMAIL:BUTTON_SEND_AGAIN-TEXT")
          }}</v-btn>
        </div>
      </form>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import localforage from "@/plugins/localforage";
import { isEmpty } from "lodash";

export default {
  name: "verify-email",
  data() {
    return {
      salt: null,
      wait: false,
    };
  },
  mounted() {
    // redirect if already activated
    if (this.authUser && this.authUser.active) {
      this.$store.dispatch(
        "setAlert",
        { type: "warning", message: "You are already activated." },
        { root: true }
      );
      this.$router.replace({ name: "home" });
    }
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  methods: {
    onFinish(rsp) {
      this.salt = rsp;
      setTimeout(() => {
        this.doVerification();
      }, 200);
    },
    ...mapActions({
      verifyEmail: "auth/verifyEmail",
      resentEmailVerificationCode: "auth/resentEmailVerificationCode",
    }),
    resendCode() {
      if (!this.auth || !this.authUser || this.authUser.active) return;
      this.resentEmailVerificationCode({ user: this.authUser, context: this });
    },
    afterSuccess() {
      localforage
        .getItem("intended")
        .then((intended) => {
          if (isEmpty(intended)) {
            this.$router.replace({ name: "home" });
            return;
          }
          localforage.removeItem("intended");
          this.$router.replace(intended);
        })
        .catch(() => {
          this.$router.replace({ name: "home" });
        });
    },
    doVerification() {
      if (!this.salt || this.salt.trim().length < 1) {
        this.$store.dispatch(
          "setAlert",
          { type: "error", message: "Verification Code can not be empty." },
          { root: true }
        );
        return;
      }
      this.wait = true;
      this.verifyEmail(this.salt.trim())
        .then(() => {
          this.wait = false;
          // this.$router.replace({ name: "home" });
          this.afterSuccess();
        })
        .catch((e) => {
          this.wait = false;
        });
    },
  },
};
</script>


